import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { width, themeType } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }
  return (
    <div className="gx-layout-sider-header">
      {/* {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar" style={{
          marginRight: 10
        }}>
          <i
            className={`  ${themeType !== THEME_TYPE_LITE ? "gx-text-white" : ""}`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          >
            {
              !sidebarCollapsed ? <MenuUnfoldOutlined style={{
                color: "#023b68",
                cursor: 'pointer'
              }} /> : <MenuFoldOutlined style={{
                color: "#023b68",
                cursor: "pointer"
              }} />
            }
          </i>
        </div>
      ) : null} */}

      <Link to="/dashboard" className="gx-site-logo">
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ? (
          <img
            alt="medsuite"
            src={"/assets/images/w-logo.png"}
            className="medsuite_logo_one"
          />
        ) : themeType === THEME_TYPE_LITE ? (
          <img
            alt="medsuite"
            src={"/assets/images/logo-white.png"}
            className="medsuite_logo_two"
          />
        ) : (
          <div
            style={{
              display: "flex",
              marginBottom: 0,
              alignItems: "center",
              gap: "10px",
            }}
          >
            <img
              alt="medsuite"
              src={"/assets/images/logo.svg"}
              className="medsuite_logo_three"
              style={{ width: "55px" }}
            />
            <h2>CHMS HQ</h2>
          </div>
        )}
      </Link>
    </div>
  );
};

export default SidebarLogo;
