import { SWITCH_LANGUAGE } from "../../constants/ActionTypes";
import {
  LAYOUT_TYPE,
  LAYOUT_TYPE_FULL,
  NAV_STYLE,
  NAV_STYLE_FIXED,
  SET_SIDEBAR_COLOR,
  SET_TOPBAR_COLOR,
  THEME_COLOR,
  THEME_TYPE,
  THEME_TYPE_SEMI_DARK,
  UPDATE_RTL_STATUS,
} from "../../constants/ThemeSetting";

const getNavStyle = localStorage.getItem("navStyle");
const getLayoutType = localStorage.getItem("layoutType");

const getLanguage = localStorage.getItem("language");

const initialSettings = {
  navStyle: getNavStyle ? getNavStyle : NAV_STYLE_FIXED,
  layoutType: getLayoutType ? getLayoutType : LAYOUT_TYPE_FULL,
  themeType: THEME_TYPE_SEMI_DARK,
  themeColor: "",
  isDirectionRTL: false,

  locale: getLanguage
    ? JSON.parse(getLanguage)
    : {
        languageId: "english",
        locale: "en",
        name: "English",
        icon: "us",
      },
  sideBarColor: "#ffffff",
  topBarColor: "#023b68",
};

const SettingsReducer = (state = initialSettings, action) => {
  switch (action.type) {
    case THEME_TYPE:
      return {
        ...state,
        themeType: action.themeType,
      };
    case THEME_COLOR:
      return {
        ...state,
        themeColor: action.themeColor,
      };

    case UPDATE_RTL_STATUS:
      return {
        ...state,
        isDirectionRTL: action.rtlStatus,
      };

    case NAV_STYLE:
      return {
        ...state,
        navStyle: action.navStyle,
      };
    case LAYOUT_TYPE:
      return {
        ...state,
        layoutType: action.layoutType,
      };

    case SWITCH_LANGUAGE:
      return {
        ...state,
        locale: action.payload,
      };

    case SET_SIDEBAR_COLOR:
      return {
        ...state,
        sideBarColor: action.color,
      };

    case SET_TOPBAR_COLOR:
      return {
        ...state,
        topBarColor: action.color,
      };
    default:
      return state;
  }
};

export default SettingsReducer;
