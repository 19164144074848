/* eslint-disable react-hooks/exhaustive-deps */

import { Button, Col, Form, Input, message, Row } from "antd";
import { Link, useHistory } from "react-router-dom";

import { UserOutlined, LockOutlined } from "@ant-design/icons";
import AxiosInstance from "../util/Api";
import { useMutation } from "react-query";
import CircularProgress from "../components/CircularProgress";
import { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import { useDispatch } from "react-redux";
import { setAuthData } from "@/appRedux/actions";

const SignIn = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        if (
            secureLocalStorage.getItem("token") &&
            secureLocalStorage.getItem("userData") &&
            secureLocalStorage.getItem("permissions")
        ) {
            history.push("/dashboard");
            dispatch(
                setAuthData({
                    user: secureLocalStorage.getItem("userData"),
                    token: secureLocalStorage.getItem("token"),
                    permissions: secureLocalStorage.getItem("permissions"),
                })
            );
        }
    }, []);

    const singin = async (values) => {
        const { data: response } = await AxiosInstance.post("auth/login", values);
        return response;
    };

    const { mutate, isLoading } = useMutation(singin, {
        onSuccess: (data) => {
            if (data.status === 1) {
                message.success(data.msg);
                secureLocalStorage.setItem("token", data.token);
                secureLocalStorage.setItem("userData", data.user);
                secureLocalStorage.setItem("permissions", data.prms);
                history.push("/dashboard");
                dispatch(
                    setAuthData({
                        user: data.user,
                        token: data.token,
                        permissions: data.prms,
                    })
                );
            } else if (data.status === 0) {
                message.warning(data.error);
            } else {
                message.error(data.msg);
            }
        },
        onError: () => {
            message.error("Server Error");
        },
    });

    const onFinish = (values) => {
        console.log("🚀 ~ file: SignIn.js:52 ~ onFinish ~ values:", values);
        mutate(values);
    };

    if (isLoading) {
        return <CircularProgress />;
    }

    return (
        <Row className="signin" style={{ margin: "0" }}>


            <Col xs={0} lg={12} className="signin__left">
                {/* <Image
                    src={img}
                    preview={false}
                    alt="authentication"
                    className="signin__left__image"
                />
                <div>
                    <p
                        style={{
                            fontSize: "20px",
                            fontStyle: "italic",
                            color: "#FFF",
                        }}
                    >
                        Welcome to CHMS
                    </p>
                </div> */}
            </Col>

            <Col
                xs={24}
                lg={12}

                className="signin__right"
            >
                <div style={{ width: "80%", margin: "auto" }}>
                    <div className="signin__right__logo">
                        <img src="/assets/images/logo.svg" alt="SME" className="logo" />
                    </div>
                    <div className="signin__right__title">
                        <h1>CHMS</h1> <p>Sign in to your account to continue.</p>
                    </div>
                    <Form
                        className="signin__right__form"
                        onFinish={onFinish}
                        layout="vertical"
                    >
                        <Form.Item
                            name="userid"
                            label="Username or Email"
                            rules={[{ required: true, message: "This field is required" }]}
                            className="singin--input"
                        >
                            <Input
                                placeholder="Username"
                                prefix={<UserOutlined />}
                                required={true}
                                size="large"
                            />
                        </Form.Item>
                        <Form.Item
                            name="password"
                            label="Password"
                            rules={[{ required: true, message: "Password is required!" }]}
                            className="singin--input"
                        >
                            <Input.Password
                                placeholder="Password"
                                prefix={<LockOutlined />}
                                required={true}
                                size="large"
                            />
                        </Form.Item>

                        <Form.Item className="forgot__password">
                            <Link to="/forgot-password">Forgot password</Link>
                        </Form.Item>

                        <div>
                            <Button
                                type="primary"
                                htmlType="submit"
                                block
                                className="signin__right__form__submit tw-h-12"
                                size="large"
                                loading={isLoading}
                            >
                                Login
                            </Button>
                        </div>
                    </Form>
                    <div className="signin__right__version">
                        Prod. v1.0.1
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default SignIn;
