export const OCCUPATION_LIST = [
  {
    id: 1,
    name: "Accounting",
    value: 1,
  },
  {
    id: 2,
    name: "Airlines/Aviation",
    value: 2,
  },
  {
    id: 3,
    name: "Alternative Dispute Resolution",
    value: 3,
  },
  {
    id: 4,
    name: "Alternative Medicine",
    value: 4,
  },
  {
    id: 5,
    name: "Animation",
    value: 5,
  },
  {
    id: 6,
    name: "Apparel/Fashion",
    value: 6,
  },
  {
    id: 7,
    name: "Architecture/Planning",
    value: 7,
  },
  {
    id: 8,
    name: "Arts/Crafts",
    value: 8,
  },
  {
    id: 9,
    name: "Automotive",
    value: 9,
  },
  {
    id: 10,
    name: "Aviation/Aerospace",
    value: 10,
  },
  {
    id: 11,
    name: "Banking/Mortgage",
    value: 11,
  },
  {
    id: 12,
    name: "Biotechnology/Greentech",
    value: 12,
  },
  {
    id: 13,
    name: "Broadcast Media",
    value: 13,
  },
  {
    id: 14,
    name: "Building Materials",
    value: 14,
  },
  {
    id: 15,
    name: "Business Supplies/Equipment",
    value: 15,
  },
  {
    id: 16,
    name: "Capital Markets/Hedge Fund/Private Equity",
    value: 16,
  },
  {
    id: 17,
    name: "Chemicals",
    value: 17,
  },
  {
    id: 18,
    name: "Civic/Social Organization",
    value: 18,
  },
  {
    id: 19,
    name: "Civil Engineering",
    value: 19,
  },
  {
    id: 20,
    name: "Commercial Real Estate",
    value: 20,
  },
  {
    id: 21,
    name: "Computer Games",
    value: 21,
  },
  {
    id: 22,
    name: "Computer Hardware",
    value: 22,
  },
  {
    id: 23,
    name: "Computer Networking",
    value: 23,
  },
  {
    id: 24,
    name: "Computer Software/Engineering",
    value: 24,
  },
  {
    id: 25,
    name: "Computer/Network Security",
    value: 25,
  },
  {
    id: 26,
    name: "Construction",
    value: 26,
  },
  {
    id: 27,
    name: "Consumer Electronics",
    value: 27,
  },
  {
    id: 28,
    name: "Consumer Goods",
    value: 28,
  },
  {
    id: 29,
    name: "Consumer Services",
    value: 29,
  },
  {
    id: 30,
    name: "Cosmetics",
    value: 30,
  },
  {
    id: 31,
    name: "Dairy",
    value: 31,
  },
  {
    id: 32,
    name: "Defense/Space",
    value: 32,
  },
  {
    id: 33,
    name: "Design",
    value: 33,
  },
  {
    id: 34,
    name: "E-Learning",
    value: 34,
  },
  {
    id: 35,
    name: "Education Management",
    value: 35,
  },
  {
    id: 36,
    name: "Electrical/Electronic Manufacturing",
    value: 36,
  },
  {
    id: 37,
    name: "Entertainment/Movie Production",
    value: 37,
  },
  {
    id: 38,
    name: "Environmental Services",
    value: 38,
  },
  {
    id: 39,
    name: "Events Services",
    value: 39,
  },
  {
    id: 40,
    name: "Executive Office",
    value: 40,
  },
  {
    id: 41,
    name: "Facilities Services",
    value: 41,
  },
  {
    id: 42,
    name: "Farming",
    value: 42,
  },
  {
    id: 43,
    name: "Financial Services",
    value: 43,
  },
  {
    id: 44,
    name: "Fine Art",
    value: 44,
  },
  {
    id: 45,
    name: "Fishery",
    value: 45,
  },
  {
    id: 46,
    name: "Food Production",
    value: 46,
  },
  {
    id: 47,
    name: "Food/Beverages",
    value: 47,
  },
  {
    id: 48,
    name: "Fundraising",
    value: 48,
  },
  {
    id: 49,
    name: "Furniture",
    value: 49,
  },
  {
    id: 50,
    name: "Gambling/Casinos",
    value: 50,
  },
  {
    id: 51,
    name: "Glass/Ceramics/Concrete",
    value: 51,
  },
  {
    id: 52,
    name: "Government Administration",
    value: 52,
  },
  {
    id: 53,
    name: "Government Relations",
    value: 53,
  },
  {
    id: 54,
    name: "Graphic Design/Web Design",
    value: 54,
  },
  {
    id: 55,
    name: "Health/Fitness",
    value: 55,
  },
  {
    id: 56,
    name: "Higher Education/Acadamia",
    value: 56,
  },
  {
    id: 57,
    name: "Hospital/Health Care",
    value: 57,
  },
  {
    id: 58,
    name: "Hospitality",
    value: 58,
  },
  {
    id: 59,
    name: "Human Resources/HR",
    value: 59,
  },
  {
    id: 60,
    name: "Import/Export",
    value: 60,
  },
  {
    id: 61,
    name: "Individual/Family Services",
    value: 61,
  },
  {
    id: 62,
    name: "Industrial Automation",
    value: 62,
  },
  {
    id: 63,
    name: "Information Services",
    value: 63,
  },
  {
    id: 64,
    name: "Information Technology/IT",
    value: 64,
  },
  {
    id: 65,
    name: "Insurance",
    value: 65,
  },
  {
    id: 66,
    name: "International Affairs",
    value: 66,
  },
  {
    id: 67,
    name: "International Trade/Development",
    value: 67,
  },
  {
    id: 68,
    name: "Internet",
    value: 68,
  },
  {
    id: 69,
    name: "Investment Banking/Venture",
    value: 69,
  },
  {
    id: 70,
    name: "Investment Management/Hedge Fund/Private Equity",
    value: 70,
  },
  {
    id: 71,
    name: "Judiciary",
    value: 71,
  },
  {
    id: 72,
    name: "Law Enforcement",
    value: 72,
  },
  {
    id: 73,
    name: "Law Practice/Law Firms",
    value: 73,
  },
  {
    id: 74,
    name: "Legal Services",
    value: 74,
  },
  {
    id: 75,
    name: "Legislative Office",
    value: 75,
  },
  {
    id: 76,
    name: "Leisure/Travel",
    value: 76,
  },
  {
    id: 77,
    name: "Library",
    value: 77,
  },
  {
    id: 78,
    name: "Logistics/Procurement",
    value: 78,
  },
  {
    id: 79,
    name: "Luxury Goods/Jewelry",
    value: 79,
  },
  {
    id: 80,
    name: "Machinery",
    value: 80,
  },
  {
    id: 81,
    name: "Management Consulting",
    value: 81,
  },
  {
    id: 82,
    name: "Maritime",
    value: 82,
  },
  {
    id: 83,
    name: "Market Research",
    value: 83,
  },
  {
    id: 84,
    name: "Marketing/Advertising/Sales",
    value: 84,
  },
  {
    id: 85,
    name: "Mechanical or Industrial Engineering",
    value: 85,
  },
  {
    id: 86,
    name: "Media Production",
    value: 86,
  },
  {
    id: 87,
    name: "Medical Equipment",
    value: 87,
  },
  {
    id: 88,
    name: "Medical Practice",
    value: 88,
  },
  {
    id: 89,
    name: "Mental Health Care",
    value: 89,
  },
  {
    id: 90,
    name: "Military Industry",
    value: 90,
  },
  {
    id: 91,
    name: "Mining/Metals",
    value: 91,
  },
  {
    id: 92,
    name: "Motion Pictures/Film",
    value: 92,
  },
  {
    id: 93,
    name: "Museums/Institutions",
    value: 93,
  },
  {
    id: 94,
    name: "Music",
    value: 94,
  },
  {
    id: 95,
    name: "Nanotechnology",
    value: 95,
  },
  {
    id: 96,
    name: "Newspapers/Journalism",
    value: 96,
  },
  {
    id: 97,
    name: "Non-Profit/Volunteering",
    value: 97,
  },
  {
    id: 98,
    name: "Oil/Energy/Solar/Greentech",
    value: 98,
  },
  {
    id: 99,
    name: "Online Publishing",
    value: 99,
  },
  {
    id: 100,
    name: "Other Industry",
    value: 100,
  },
  {
    id: 101,
    name: "Outsourcing/Offshoring",
    value: 101,
  },
  {
    id: 102,
    name: "Package/Freight Delivery",
    value: 102,
  },
  {
    id: 103,
    name: "Packaging/Containers",
    value: 103,
  },
  {
    id: 104,
    name: "Paper/Forest Products",
    value: 104,
  },
  {
    id: 105,
    name: "Performing Arts",
    value: 105,
  },
  {
    id: 106,
    name: "Pharmaceuticals",
    value: 106,
  },
  {
    id: 107,
    name: "Philanthropy",
    value: 107,
  },
  {
    id: 108,
    name: "Photography",
    value: 108,
  },
  {
    id: 109,
    name: "Plastics",
    value: 109,
  },
  {
    id: 110,
    name: "Political Organization",
    value: 110,
  },
  {
    id: 111,
    name: "Primary/Secondary Education",
    value: 111,
  },
  {
    id: 112,
    name: "Printing",
    value: 112,
  },
  {
    id: 113,
    name: "Professional Training",
    value: 113,
  },
  {
    id: 114,
    name: "Program Development",
    value: 114,
  },
  {
    id: 115,
    name: "Public Relations/PR",
    value: 115,
  },
  {
    id: 116,
    name: "Public Safety",
    value: 116,
  },
  {
    id: 117,
    name: "Publishing Industry",
    value: 117,
  },
  {
    id: 118,
    name: "Railroad Manufacture",
    value: 118,
  },
  {
    id: 119,
    name: "Ranching",
    value: 119,
  },
  {
    id: 120,
    name: "Real Estate/Mortgage",
    value: 120,
  },
  {
    id: 121,
    name: "Recreational Facilities/Services",
    value: 121,
  },
  {
    id: 122,
    name: "Religious Institutions",
    value: 122,
  },
  {
    id: 123,
    name: "Renewables/Environment",
    value: 123,
  },
  {
    id: 124,
    name: "Research Industry",
    value: 124,
  },
  {
    id: 125,
    name: "Restaurants",
    value: 125,
  },
  {
    id: 126,
    name: "Retail Industry",
    value: 126,
  },
  {
    id: 127,
    name: "Security/Investigations",
    value: 127,
  },
  {
    id: 128,
    name: "Semiconductors",
    value: 128,
  },
  {
    id: 129,
    name: "Shipbuilding",
    value: 129,
  },
  {
    id: 130,
    name: "Sporting Goods",
    value: 130,
  },
  {
    id: 131,
    name: "Sports",
    value: 131,
  },
  {
    id: 132,
    name: "Staffing/Recruiting",
    value: 132,
  },
  {
    id: 133,
    name: "Supermarkets",
    value: 133,
  },
  {
    id: 134,
    name: "Telecommunications",
    value: 134,
  },
  {
    id: 135,
    name: "Textiles",
    value: 135,
  },
  {
    id: 136,
    name: "Think Tanks",
    value: 136,
  },
  {
    id: 137,
    name: "Tobacco",
    value: 137,
  },
  {
    id: 138,
    name: "Translation/Localization",
    value: 138,
  },
  {
    id: 139,
    name: "Transportation",
    value: 139,
  },
  {
    id: 140,
    name: "Utilities",
    value: 140,
  },
  {
    id: 141,
    name: "Venture Capital/VC",
    value: 141,
  },
  {
    id: 142,
    name: "Veterinary",
    value: 142,
  },
  {
    id: 143,
    name: "Warehousing",
    value: 143,
  },
  {
    id: 144,
    name: "Wholesale",
    value: 144,
  },
  {
    id: 145,
    name: "Wine/Spirits",
    value: 145,
  },
  {
    id: 146,
    name: "Wireless",
    value: 146,
  },
  {
    id: 147,
    name: "Writing/Editing",
    value: 147,
  },
  {
    id: 148,
    name: "Student",
    value: 148,
  },
  {
    id: 149,
    name: "Unemployed",
    value: 149,
  },
  {
    id: 150,
    name: "Other",
    value: 150,
  },
];

export const EDUCATIONAL_LEVEL = [
  {
    id: 1,
    value: 1,
    name: "Below High School",
  },
  {
    id: 2,
    value: 2,
    name: "High School Certificate",
  },
  {
    id: 3,
    value: 3,
    name: "Associates",
  },
  {
    id: 4,
    value: 4,
    name: "Bachelors",
  },
  {
    id: 5,
    value: 5,
    name: "Masters",
  },
  {
    id: 6,
    value: 6,
    name: "Doctorate",
  },
  {
    id: 7,
    value: 7,
    name: "PHD",
  },
  {
    id: 8,
    value: 8,
    name: "Other",
  },
];

export const MARITAL_STATUS = [
  {
    id: 1,
    value: 1,
    name: "Single",
  },
  {
    id: 2,
    value: 2,
    name: "Married",
  },
  {
    id: 3,
    value: 3,
    name: "Divorced",
  },
  {
    id: 4,
    value: 4,
    name: "Separated",
  },
  {
    id: 5,
    value: 5,
    name: "Widow",
  },
  {
    id: 6,
    value: 6,
    name: "Widower",
  },
];

export const SOCIAL_MEDIA = [
  {
    id: 1,
    value: 1,
    name: "Facebook",
  },
  {
    id: 2,
    value: 2,
    name: "Twitter",
  },
  {
    id: 3,
    value: 3,
    name: "Instagram",
  },
  {
    id: 4,
    value: 4,
    name: "LinkedIn",
  },
  {
    id: 5,
    value: 5,
    name: "YouTube",
  },
  {
    id: 6,
    value: 6,
    name: "WhatsApp",
  },
];

export const RELATIONSHIP = [
  {
    id: 1,
    name: "Father",
  },
  {
    id: 2,
    name: "Mother",
  },
  {
    id: 3,
    name: "Brother",
  },
  {
    id: 4,
    name: "Sister",
  },
  {
    id: 5,
    name: "Son",
  },
  {
    id: 6,
    name: "Daughter",
  },
  {
    id: 7,
    name: "Husband",
  },
  {
    id: 8,
    name: "Wife",
  },
];

export function getRelationshipById(id) {
  return RELATIONSHIP.find((relation) => relation.id === id)?.name;
}

export const COUNTRIES = [
  {
    id: 1,
    name: "Afghanistan",
  },
  {
    id: 2,
    name: "Albania",
  },
  {
    id: 3,
    name: "Algeria",
  },
  {
    id: 4,
    name: "American Samoa",
  },
  {
    id: 5,
    name: "Andorra",
  },
  {
    id: 6,
    name: "Angola",
  },
  {
    id: 7,
    name: "Anguilla",
  },
  {
    id: 8,
    name: "Antarctica",
  },
  {
    id: 9,
    name: "Antigua and Barbuda",
  },
  {
    id: 10,
    name: "Argentina",
  },
  {
    id: 11,
    name: "Armenia",
  },
  {
    id: 12,
    name: "Aruba",
  },
  {
    id: 13,
    name: "Australia",
  },
  {
    id: 14,
    name: "Austria",
  },
  {
    id: 15,
    name: "Azerbaijan",
  },
  {
    id: 16,
    name: "Bahamas",
  },
  {
    id: 17,
    name: "Bahrain",
  },
  {
    id: 18,
    name: "Bangladesh",
  },
  {
    id: 19,
    name: "Barbados",
  },
  {
    id: 20,
    name: "Belarus",
  },
  {
    id: 21,
    name: "Belgium",
  },
  {
    id: 22,
    name: "Belize",
  },
  {
    id: 23,
    name: "Benin",
  },
  {
    id: 24,
    name: "Bermuda",
  },
  {
    id: 25,
    name: "Bhutan",
  },
  {
    id: 26,
    name: "Bolivia",
  },
  {
    id: 27,
    name: "Bosnia and Herzegovina",
  },
  {
    id: 28,
    name: "Botswana",
  },
  {
    id: 29,
    name: "Bouvet Island",
  },
  {
    id: 30,
    name: "Brazil",
  },
  {
    id: 31,
    name: "British Indian Ocean Territory",
  },
  {
    id: 32,
    name: "British Virgin Islands",
  },
  {
    id: 33,
    name: "Brunei",
  },
  {
    id: 34,
    name: "Bulgaria",
  },
  {
    id: 35,
    name: "Burkina Faso",
  },
  {
    id: 36,
    name: "Burundi",
  },
  {
    id: 37,
    name: "Cambodia",
  },
  {
    id: 38,
    name: "Cameroon",
  },
  {
    id: 39,
    name: "Canada",
  },
  {
    id: 40,
    name: "Cape Verde",
  },
  {
    id: 41,
    name: "Cayman Islands",
  },
  {
    id: 42,
    name: "Central African Republic",
  },
  {
    id: 43,
    name: "Chad",
  },
  {
    id: 44,
    name: "Chile",
  },
  {
    id: 45,
    name: "China",
  },
  {
    id: 46,
    name: "Christmas Island",
  },
  {
    id: 47,
    name: "Cocos Islands",
  },
  {
    id: 48,
    name: "Colombia",
  },
  {
    id: 49,
    name: "Comoros",
  },
  {
    id: 50,
    name: "Cook Islands",
  },
  {
    id: 51,
    name: "Costa Rica",
  },
  {
    id: 52,
    name: "Croatia",
  },
  {
    id: 53,
    name: "Cuba",
  },
  {
    id: 54,
    name: "Cyprus",
  },
  {
    id: 55,
    name: "Czech Republic",
  },
  {
    id: 56,
    name: "Democratic Republic of the Congo",
  },
  {
    id: 57,
    name: "Denmark",
  },
  {
    id: 58,
    name: "Djibouti",
  },
  {
    id: 59,
    name: "Dominica",
  },
  {
    id: 60,
    name: "Dominican Republic",
  },
  {
    id: 61,
    name: "East Timor",
  },
  {
    id: 62,
    name: "Ecuador",
  },
  {
    id: 63,
    name: "Egypt",
  },
  {
    id: 64,
    name: "El Salvador",
  },
  {
    id: 65,
    name: "Equatorial Guinea",
  },
  {
    id: 66,
    name: "Eritrea",
  },
  {
    id: 67,
    name: "Estonia",
  },
  {
    id: 68,
    name: "Ethiopia",
  },
  {
    id: 69,
    name: "Falkland Islands",
  },
  {
    id: 70,
    name: "Faroe Islands",
  },
  {
    id: 71,
    name: "Fiji",
  },
  {
    id: 72,
    name: "Finland",
  },
  {
    id: 73,
    name: "France",
  },
  {
    id: 74,
    name: "French Guiana",
  },
  {
    id: 75,
    name: "French Polynesia",
  },
  {
    id: 76,
    name: "French Southern Territories",
  },
  {
    id: 77,
    name: "Gabon",
  },
  {
    id: 78,
    name: "Gambia",
  },
  {
    id: 79,
    name: "Georgia",
  },
  {
    id: 80,
    name: "Germany",
  },
  {
    id: 81,
    name: "Ghana",
  },
  {
    id: 82,
    name: "Gibraltar",
  },
  {
    id: 83,
    name: "Greece",
  },
  {
    id: 84,
    name: "Greenland",
  },
  {
    id: 85,
    name: "Grenada",
  },
  {
    id: 86,
    name: "Guadeloupe",
  },
  {
    id: 87,
    name: "Guam",
  },
  {
    id: 88,
    name: "Guatemala",
  },
  {
    id: 89,
    name: "Guinea",
  },
  {
    id: 90,
    name: "Guinea-Bissau",
  },
  {
    id: 91,
    name: "Guyana",
  },
  {
    id: 92,
    name: "Haiti",
  },
  {
    id: 93,
    name: "Heard Island and McDonald Islands",
  },
  {
    id: 94,
    name: "Honduras",
  },
  {
    id: 95,
    name: "Hong Kong",
  },
  {
    id: 96,
    name: "Hungary",
  },
  {
    id: 97,
    name: "Iceland",
  },
  {
    id: 98,
    name: "India",
  },
  {
    id: 99,
    name: "Indonesia",
  },
  {
    id: 100,
    name: "Iran",
  },
  {
    id: 101,
    name: "Iraq",
  },
  {
    id: 102,
    name: "Ireland",
  },
  {
    id: 103,
    name: "Israel",
  },
  {
    id: 104,
    name: "Italy",
  },
  {
    id: 105,
    name: "Ivory Coast",
  },
  {
    id: 106,
    name: "Jamaica",
  },
  {
    id: 107,
    name: "Japan",
  },
  {
    id: 108,
    name: "Jordan",
  },
  {
    id: 109,
    name: "Kazakhstan",
  },
  {
    id: 110,
    name: "Kenya",
  },
  {
    id: 111,
    name: "Kiribati",
  },
  {
    id: 112,
    name: "Kuwait",
  },
  {
    id: 113,
    name: "Kyrgyzstan",
  },
  {
    id: 114,
    name: "Laos",
  },
  {
    id: 115,
    name: "Latvia",
  },
  {
    id: 116,
    name: "Lebanon",
  },
  {
    id: 117,
    name: "Lesotho",
  },
  {
    id: 118,
    name: "Liberia",
  },
  {
    id: 119,
    name: "Libya",
  },
  {
    id: 120,
    name: "Liechtenstein",
  },
  {
    id: 121,
    name: "Lithuania",
  },
  {
    id: 122,
    name: "Luxembourg",
  },
  {
    id: 123,
    name: "Macao",
  },
  {
    id: 124,
    name: "Macedonia",
  },
  {
    id: 125,
    name: "Madagascar",
  },
  {
    id: 126,
    name: "Malawi",
  },
  {
    id: 127,
    name: "Malaysia",
  },
  {
    id: 128,
    name: "Maldives",
  },
  {
    id: 129,
    name: "Mali",
  },
  {
    id: 130,
    name: "Malta",
  },
  {
    id: 131,
    name: "Marshall Islands",
  },
  {
    id: 132,
    name: "Martinique",
  },
  {
    id: 133,
    name: "Mauritania",
  },
  {
    id: 134,
    name: "Mauritius",
  },
  {
    id: 135,
    name: "Mayotte",
  },
  {
    id: 136,
    name: "Mexico",
  },
  {
    id: 137,
    name: "Micronesia",
  },
  {
    id: 138,
    name: "Moldova",
  },
  {
    id: 139,
    name: "Monaco",
  },
  {
    id: 140,
    name: "Mongolia",
  },
  {
    id: 141,
    name: "Montserrat",
  },
  {
    id: 142,
    name: "Morocco",
  },
  {
    id: 143,
    name: "Mozambique",
  },
  {
    id: 144,
    name: "Myanmar",
  },
  {
    id: 145,
    name: "Namibia",
  },
  {
    id: 146,
    name: "Nauru",
  },
  {
    id: 147,
    name: "Nepal",
  },
  {
    id: 148,
    name: "Netherlands",
  },
  {
    id: 149,
    name: "Netherlands Antilles",
  },
  {
    id: 150,
    name: "New Caledonia",
  },
  {
    id: 151,
    name: "New Zealand",
  },
  {
    id: 152,
    name: "Nicaragua",
  },
  {
    id: 153,
    name: "Niger",
  },
  {
    id: 154,
    name: "Nigeria",
  },
  {
    id: 155,
    name: "Niue",
  },
  {
    id: 156,
    name: "Norfolk Island",
  },
  {
    id: 157,
    name: "North Korea",
  },
  {
    id: 158,
    name: "Northern Mariana Islands",
  },
  {
    id: 159,
    name: "Norway",
  },
  {
    id: 160,
    name: "Oman",
  },
  {
    id: 161,
    name: "Pakistan",
  },
  {
    id: 162,
    name: "Palau",
  },
  {
    id: 163,
    name: "Palestinian Territory",
  },
  {
    id: 164,
    name: "Panama",
  },
  {
    id: 165,
    name: "Papua New Guinea",
  },
  {
    id: 166,
    name: "Paraguay",
  },
  {
    id: 167,
    name: "Peru",
  },
  {
    id: 168,
    name: "Philippines",
  },
  {
    id: 169,
    name: "Pitcairn",
  },
  {
    id: 170,
    name: "Poland",
  },
  {
    id: 171,
    name: "Portugal",
  },
  {
    id: 172,
    name: "Puerto Rico",
  },
  {
    id: 173,
    name: "Qatar",
  },
  {
    id: 174,
    name: "Republic of the Congo",
  },
  {
    id: 175,
    name: "Reunion",
  },
  {
    id: 176,
    name: "Romania",
  },
  {
    id: 177,
    name: "Russia",
  },
  {
    id: 178,
    name: "Rwanda",
  },
  {
    id: 179,
    name: "Saint Helena",
  },
  {
    id: 180,
    name: "Saint Kitts and Nevis",
  },
  {
    id: 181,
    name: "Saint Lucia",
  },
  {
    id: 182,
    name: "Saint Pierre and Miquelon",
  },
  {
    id: 183,
    name: "Saint Vincent and the Grenadines",
  },
  {
    id: 184,
    name: "Samoa",
  },
  {
    id: 185,
    name: "San Marino",
  },
  {
    id: 186,
    name: "Sao Tome and Principe",
  },
  {
    id: 187,
    name: "Saudi Arabia",
  },
  {
    id: 188,
    name: "Senegal",
  },
  {
    id: 189,
    name: "Serbia and Montenegro",
  },
  {
    id: 190,
    name: "Seychelles",
  },
  {
    id: 191,
    name: "Sierra Leone",
  },
  {
    id: 192,
    name: "Singapore",
  },
  {
    id: 193,
    name: "Slovakia",
  },
  {
    id: 194,
    name: "Slovenia",
  },
  {
    id: 195,
    name: "Solomon Islands",
  },
  {
    id: 196,
    name: "Somalia",
  },
  {
    id: 197,
    name: "South Africa",
  },
  {
    id: 198,
    name: "South Georgia and the South Sandwich Islands",
  },
  {
    id: 199,
    name: "South Korea",
  },
  {
    id: 200,
    name: "Spain",
  },
  {
    id: 201,
    name: "Sri Lanka",
  },
  {
    id: 202,
    name: "Sudan",
  },
  {
    id: 203,
    name: "Suriname",
  },
  {
    id: 204,
    name: "Svalbard and Jan Mayen",
  },
  {
    id: 205,
    name: "Swaziland",
  },
  {
    id: 206,
    name: "Sweden",
  },
  {
    id: 207,
    name: "Switzerland",
  },
  {
    id: 208,
    name: "Syria",
  },
  {
    id: 209,
    name: "Taiwan",
  },
  {
    id: 210,
    name: "Tajikistan",
  },
  {
    id: 211,
    name: "Tanzania",
  },
  {
    id: 212,
    name: "Thailand",
  },
  {
    id: 213,
    name: "Togo",
  },
  {
    id: 214,
    name: "Tokelau",
  },
  {
    id: 215,
    name: "Tonga",
  },
  {
    id: 216,
    name: "Trinidad and Tobago",
  },
  {
    id: 217,
    name: "Tunisia",
  },
  {
    id: 218,
    name: "Turkey",
  },
  {
    id: 219,
    name: "Turkmenistan",
  },
  {
    id: 220,
    name: "Turks and Caicos Islands",
  },
  {
    id: 221,
    name: "Tuvalu",
  },
  {
    id: 222,
    name: "U.S. Virgin Islands",
  },
  {
    id: 223,
    name: "Uganda",
  },
  {
    id: 224,
    name: "Ukraine",
  },
  {
    id: 225,
    name: "United Arab Emirates",
  },
  {
    id: 226,
    name: "United Kingdom",
  },
  {
    id: 227,
    name: "United States",
  },
  {
    id: 228,
    name: "United States Minor Outlying Islands",
  },
  {
    id: 229,
    name: "Uruguay",
  },
  {
    id: 230,
    name: "Uzbekistan",
  },
  {
    id: 231,
    name: "Vanuatu",
  },
  {
    id: 232,
    name: "Vatican",
  },
  {
    id: 233,
    name: "Venezuela",
  },
  {
    id: 234,
    name: "Vietnam",
  },
  {
    id: 235,
    name: "Wallis and Futuna",
  },
  {
    id: 236,
    name: "Western Sahara",
  },
  {
    id: 237,
    name: "Yemen",
  },
  {
    id: 238,
    name: "Zambia",
  },
  {
    id: 239,
    name: "Zimbabwe",
  },
];

export const GENDER = [
  {
    id: 1,
    name: "Male",
  },
  {
    id: 2,
    name: "Female",
  },
];

export const STATUS = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Inactive",
  },
];

export const MEMBER_STATUS_SEARCH = [
  {
    id: 1,
    name: "Active",
  },
  {
    id: 0,
    name: "Inactive",
  },
  {
    id: 6,
    name: "Transferred",
  },
];

export const ACTIVITY_STATUS = [
    {
        "id": 0,
        "name": "Pending"
    },
    {
        "id": 2,
        "name": "In Progress"
    },
    {
        "id": 3,
        "name": "Canceled"
    },
    {
        "id": 1,
        "name": "Completed"
    }
]

export const MEMBER_STATUS = [
  {
    id: 1,
    name: "Active Member",
  },
  {
    id: 2,
    name: "Inactive Member",
  },
  {
    id: 4,
    name: "Deceased",
  },
  {
    id: 5,
    name: "Retired",
  },
];

export const MEMBER_Inactive_Reason = [
  {
    id: "Duplicate",
    name: "Duplicate",
  },
  {
    id: "Backsliding",
    name: "Backsliding",
  },
  {
    id: "No More Attending",
    name: "No More Attending",
  }
];

export const CONTACT_PREFERENCE = [
  {
    id: 1,
    name: "Email",
  },
  {
    id: 2,
    name: "Phone",
  },
];

export const MEMBER_ROLES = [
  {
    id: 1,
    name: "Member",
  },
  {
    id: 2,
    name: "Deacon",
  },
  {
    id: 3,
    name: "Deaconess",
  },
  {
    id: 4,
    name: "Elder",
  },
  {
    id: 5,
    name: "Minister’s Wife",
  },
  {
    id: 6,
    name: "Bi-Vocational",
  },
  {
    id: 7,
    name: "P/Overseer",
  },
  {
    id: 8,
    name: "Overseer",
  },
  {
    id: 9,
    name: "Pastor",
  },
  {
    id: 10,
    name: "Evangelist",
  },
  {
    id: 11,
    name: "Prophet",
  },
  {
    id: 12,
    name: "Apostle",
  },
  {
    id: 13,
    name: "Retired Minister",
  },
  {
    id: 14,
    name: "Retired Minister Wife",
  },
];

export const MEMBER_ROLES_NEW = [
  {
    id: "Member",
    name: "Member",
  },
  {
    id: "Deacon",
    name: "Deacon",
  },
  {
    id: "Deaconess",
    name: "Deaconess",
  },
  {
    id: "Elder",
    name: "Elder",
  },
  {
    id: "Minister’s Wife",
    name: "Minister’s Wife",
  },
  {
    id: "Bi-Vocational",
    name: "Bi-Vocational",
  },
  {
    id: "P/Overseer",
    name: "P/Overseer",
  },
  {
    id: "Overseer",
    name: "Overseer",
  },
  {
    id: "Pastor",
    name: "Pastor",
  },
  {
    id: "Evangelist",
    name: "Evangelist",
  },
  {
    id: "Prophet",
    name: "Prophet",
  },
  {
    id: "Apostle",
    name: "Apostle",
  },
  {
    id: "Retired Minister",
    name: "Retired Minister",
  },
  {
    id: "Retired Minister Wife",
    name: "Retired Minister Wife",
  },
];

export const HOLY_SPIRIT_EVIDENCE = [
  {
    id: 1,
    name: "Yes",
  },
  {
    id: 2,
    name: "No",
  },
];

export const CHURCH_DISCIPLINE_STATUS = [
  {
    id: 1,
    name: "Suspended",
  },
  {
    id: 2,
    name: "Interdicted",
  },
  {
    id: 3,
    name: "Suspended & Revoked",
  },
  {
    id: 4,
    name: "Revocation",
  },
  {
    id: 5,
    name: "Re-Instatement",
  },
];

export const NATURAL_SPIRITUAL = [
  {
    id: 1,
    name: "Word of Wisdom",
  },
  {
    id: 2,
    name: "Word of Knowledge",
  },
  {
    id: 3,
    name: "Faith",
  },
  {
    id: 4,
    name: "Gifts of Healing",
  },
  {
    id: 5,
    name: "Working of Miracles",
  },
  {
    id: 6,
    name: "Gift of Prophecy",
  },
  {
    id: 7,
    name: "Discerning of Spirits",
  },
  {
    id: 8,
    name: "Diverse Tongues",
  },
  {
    id: 9,
    name: "Interpretation of Tongues",
  },
];

export const ACCESS_LEVEL = [
  {
    id: 1,
    name: "All",
  },
  {
    id: 2,
    name: "Family",
  },
  {
    id: 3,
    name: "Only me",
  },
];
