import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Drawer, Layout } from "antd";

import SidebarContent from "./SidebarContent";
import { toggleCollapsedSideNav } from "../../appRedux/actions";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import { LogoutOutlined } from "@ant-design/icons";
import secureLocalStorage from "react-secure-storage";
import { useHistory } from "react-router-dom";
import { useQueryClient } from "react-query";
import IntlMessages from "@/util/IntlMessages";
import { APP_VERSION } from "@/util/config";

const { Sider } = Layout;

const Sidebar = () => {
  let [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const { themeType, navStyle } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const dispatch = useDispatch();

  const onToggleCollapsedNav = () => {
    dispatch(toggleCollapsedSideNav(!navCollapsed));
  };

  useEffect(() => {
    setSidebarCollapsed(navStyle === NAV_STYLE_MINI_SIDEBAR);
  }, [navStyle]);

  let drawerStyle = "gx-collapsed-sidebar";

  if (navStyle === NAV_STYLE_FIXED) {
    drawerStyle = "";
  } else if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar gx-mini-custom-sidebar";
  } else if (navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) {
    drawerStyle = "gx-custom-sidebar";
  } else if (navStyle === NAV_STYLE_MINI_SIDEBAR) {
    drawerStyle = "gx-mini-sidebar";
  } else if (navStyle === NAV_STYLE_DRAWER) {
    drawerStyle = "gx-collapsed-sidebar";
  }
  if (
    (navStyle === NAV_STYLE_FIXED ||
      navStyle === NAV_STYLE_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR) &&
    width < TAB_SIZE
  ) {
    drawerStyle = "gx-collapsed-sidebar";
  }

  return (
    <Sider
      className={`gx-app-sidebar chms__sider print-hidden ${drawerStyle} ${themeType !== THEME_TYPE_LITE ? "gx-layout-sider-dark" : null
        }`}
      // trigger={null}
      collapsed={
        width < TAB_SIZE
          ? false
          : sidebarCollapsed || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR
      }
      theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
      trigger={width < TAB_SIZE ? null : <SidebarFooter sidebarCollapsed={sidebarCollapsed} />}
      collapsible
    >
      {navStyle === NAV_STYLE_DRAWER || width < TAB_SIZE ? (
        <Drawer
          className={`gx-drawer-sidebar ${themeType !== THEME_TYPE_LITE ? "gx-drawer-sidebar-dark" : null
            }`}
          placement="left"
          closable={false}
          onClose={onToggleCollapsedNav}
          open={navCollapsed}
        >
          <SidebarContent
            sidebarCollapsed={sidebarCollapsed}
            setSidebarCollapsed={setSidebarCollapsed}
          />
        </Drawer>
      ) : (
        <SidebarContent
          sidebarCollapsed={sidebarCollapsed}
          setSidebarCollapsed={setSidebarCollapsed}
        />
      )}
    </Sider>
  );
};
export default Sidebar;



const SidebarFooter = ({ sidebarCollapsed }) => {
  const history = useHistory();
  const queryClient = useQueryClient();
  const logouthandler = () => {
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("userData");
    secureLocalStorage.removeItem("permissions");
    history.push("/signin");
    queryClient.removeQueries();
  };

  return sidebarCollapsed ? (
    <Button
      className="trigger_button_only"
      type="primary"
      onClick={logouthandler}
      icon={<LogoutOutlined />}
    />
  ) : (
    <div
      className="sidebar__footer-container"
      data-before={`Version ${APP_VERSION}`}
    >
      <Button
        size="large"
        type="link"
        icon={<LogoutOutlined style={{ margin: '0 5px' }} />}
        block
        onClick={logouthandler}
        style={{
          color: 'white',
          width: '50%',
          height: '100%',
          marginLeft: 'auto !important',
          fontSize: '14px',
        }}
      >
        <IntlMessages id="Logout" />
      </Button>
    </div>
  );
};