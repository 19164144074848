import { capFirstWordFirstLetter, capSentenceFirstLetter } from '@/util/CISUI';
import { css } from '@emotion/css';
import { Form, Select as AntSelect } from 'antd';
import { useIntl } from 'react-intl';
import { CaretRightFilled } from "@ant-design/icons";
import React from "react";


const InputSelect = ({
  label = '',
  items = [],
  required = false,
  ...props
}) => {
  const intl = useIntl();

  if (props.initialValue) {
    return (
      <Form.Item
        label={capSentenceFirstLetter(label)}
        name={props.name}
        rules={[
          {
            required: required,
            message: intl.formatMessage({
              id: `Please select ${capSentenceFirstLetter(label)}`,
            }),
          },
        ]}
        initialValue={props.initialValue}
        className={css`
        .ant-form-item-label{
          padding-bottom: 0!important;
        }
      `}
      >
        <AntSelect
          {...props}
          className={`cis_select ${props.className}`}
          placeholder={intl.formatMessage({
            id: capFirstWordFirstLetter(props.placeholder || 'Select an option'),
          })}
          showSearch
          allowClear
          loading={props.isLoading}
        >
          {
            label === "Local Assembly" ? <>
              {
                items?.map((item, index) => (
                  <AntSelect.Option key={index} value={item.id}>
                    <div className="entity_col">
                      <p style={{ fontWeight: "600", padding: '2px' }}>
                        <CaretRightFilled style={{ color: "green", fontSize: "9px" }} />{" "}
                        {item?.local}
                      </p>
                      <p>
                        <CaretRightFilled style={{ color: "blue", fontSize: "9px", padding: '2px' }} />{" "}
                        {item?.district}
                      </p>
                      <p>
                        <CaretRightFilled style={{ color: "orange", fontSize: "9px", padding: '2px' }} />{" "}
                        {item?.region}
                      </p>
                    </div>
                  </AntSelect.Option>
                ))
              }
            </> : <>
              {
                items?.map((item, index) => (
                  <AntSelect.Option key={index} value={item.id}>
                    {item.name}
                  </AntSelect.Option>
                ))
              }
            </>
          }
        </AntSelect>
      </Form.Item>
    );
  } else {
    return (
      <Form.Item
        label={capSentenceFirstLetter(label)}
        name={props.name}
        rules={[
          {
            required: required,
            message: intl.formatMessage({
              id: `Please select ${capSentenceFirstLetter(label)}`,
            }),
          },
        ]}
        // initialValue={props.initialValue}
        className={css`
        .ant-form-item-label{
          padding-bottom: 0!important;
        }
      `}
      >
        <AntSelect
          {...props}
          className={`cis_select ${props.className}`}
          placeholder={intl.formatMessage({
            id: capFirstWordFirstLetter(props.placeholder || 'Select an option'),
          })}
          showSearch
          allowClear
          loading={props.isLoading}
        >
          {
            label === "Local Assembly" ? <>
              {
                items?.map((item, index) => (
                  <AntSelect.Option key={index} value={item.id}>
                    <div className="entity_col">
                      <p style={{ fontWeight: "600", padding: '2px' }}>
                        <CaretRightFilled style={{ color: "green", fontSize: "9px" }} />{" "}
                        {item?.local}
                      </p>
                      <p>
                        <CaretRightFilled style={{ color: "blue", fontSize: "9px", padding: '2px' }} />{" "}
                        {item?.district}
                      </p>
                      <p>
                        <CaretRightFilled style={{ color: "orange", fontSize: "9px", padding: '2px' }} />{" "}
                        {item?.region}
                      </p>
                    </div>
                  </AntSelect.Option>
                ))
              }
            </> : <>
              {
                items?.map((item, index) => (
                  <AntSelect.Option key={index} value={item.id}>
                    {item.name}
                  </AntSelect.Option>
                ))
              }
            </>
          }
        </AntSelect>
      </Form.Item>
    );
  }

};

export default InputSelect;
