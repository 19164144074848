/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Avatar, Menu, Popover } from "antd";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import { useQuery, useQueryClient } from "react-query";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import SidebarLoading from "./SidebarLoading";
import { GetUserImage, GetUserInfo } from "../../util/CISUI";
import axios from "../../util/Api";
import { API_URL } from "../../util/config";
import secureLocalStorage from "react-secure-storage";
// import DATA from "./menu.json";
import { css } from "@emotion/css";

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const location = useLocation();
  const [selectedKeys, setSelectedKeys] = useState("/dashboard");
  // const [defaultOpenKeys, setDefaultOpenKeys] = useState("");
  const { sideBarColor } = useSelector(({ settings }) => settings);

  const { user } = useSelector(({ auth }) => auth);

  const { navStyle, themeType } = useSelector(({ settings }) => settings);
  const history = useHistory();
  const queryClient = useQueryClient();
  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const getNavStyleSubMenuClass = (navStyle) => {
    if (navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR) {
      return "gx-no-header-submenu-popup";
    }
    return "";
  };

  // const selectedKeys = pathname.substr(1);
  // const defaultOpenKeys = selectedKeys.split('/')[1];

  const [userMenus, setUserMenus] = useState([]);

  const { data, isLoading } = useQuery(
    ["usermenu"],
    () =>
      axios
        .post(`${API_URL}/sys/usermenu`, null, {
          headers: { Authorization: secureLocalStorage.getItem("token") },
        })
        .then((res) => res.data.data),
    { cacheTime: Infinity, staleTime: Infinity }
  );

  const logouthandler = () => {
    // localStorage.removeItem("token");
    // localStorage.removeItem("userData");
    secureLocalStorage.removeItem("token");
    secureLocalStorage.removeItem("userData");
    secureLocalStorage.removeItem("permissions");
    history.push("/signin");
    queryClient.removeQueries();
  };

  // useEffect(() => {
  //   if (data) {
  //     // ADD MENU ITEMS HERE
  //     const dashboardMenu = {
  //       "_id": 1,
  //       "id": 1,
  //       "name": "Analytics",
  //       "label": "Analytics",
  //       "urlkey": "",
  //       "icon": "fa fas-analytics",
  //       "color": "",
  //       "modules": [
  //           {
  //               "_id": 1,
  //               "id": 1,
  //               "name": "Dashboard",
  //               "label": "Dashboard",
  //               "urlkey": "company",
  //               "icon": "icon icon-dashboard",
  //               "submodule": []

  //           }
  //       ]
  //   }

  //     // add item in 1st position
  //     data.unshift(dashboardMenu);


  //     const userMenu = data?.map((item) => {
  //       return {
  //         label: item.name,
  //         key: item.id + item.urlkey,
  //         icon: <i className={item.icon} />,
  //         type: "group",

  //         children: item?.modules?.map((child) => {
  //           return {
  //             // label: child.label,

  //             label:
  //               child?.submodule?.length === 0 ? (
  //                 <Link to={`/${child.urlkey}`}>{child.label}</Link>
  //               ) : (
  //                 child.label
  //               ),
  //             key: child.id + child.urlkey,
  //             icon: <i className={child.icon} />,
  //             popupClassName: getNavStyleSubMenuClass,
  //             children:
  //               child?.submodule?.length !== 0
  //                 ? child?.submodule?.map((child2) => {
  //                     // if (
  //                     //   `/${item.urlkey}/${child2.urlkey}` === location.pathname
  //                     // ) {
  //                     //   setDefaultOpenKeys([
  //                     //     `/${item.urlkey}/${child.urlkey}`,
  //                     //     `/${item.urlkey}/${child2.urlkey}`,
  //                     //     item.urlkey,
  //                     //   ]);
  //                     //   setSelectedKeys([`/${item.urlkey}/${child2.urlkey}`]);
  //                     // }
  //                     return {
  //                       // label: (
  //                       //   <Link to={`/${child.urlkey}/${child2.urlkey}`}>
  //                       //     {child2.label}
  //                       //   </Link>
  //                       // ),
  //                       label: child2.menu?.length === 0 ? (
  //                         <Link to={`/${child.urlkey}/${child2.urlkey}`}>{child2.label}</Link>
  //                       ): child2.label,
  //                       // icon: <i className={child.icon} />,
  //                       key: child2.id + child2.urlkey,
  //                       children:
  //                         child2?.menu?.length !== 0
  //                           ? child2?.menu?.map((child3) => {
  //                               if (
  //                                 `/${child.urlkey}/${child3.urlkey}` ===
  //                                 location.pathname
  //                               ) {
  //                                 // setDefaultOpenKeys([
  //                                 //   `/${item.urlkey}/${child.urlkey}`,
  //                                 //   `/${item.urlkey}/${child2.urlkey}`,
  //                                 //   `/${item.urlkey}/${child3.urlkey}`,
  //                                 //   item.urlkey,
  //                                 // ]);
  //                                 setSelectedKeys([
  //                                   `/${child.urlkey}/${child3.urlkey}`,
  //                                 ]);
  //                               }
  //                               return {
  //                                 label: (
  //                                   <Link
  //                                     to={`/${child.urlkey}/${child3.urlkey}`}
  //                                   >
  //                                     {child3.label}
  //                                   </Link>
  //                                 ),
  //                                 key: child3.id + child3.urlkey,
  //                               };
  //                             })
  //                           : null,
  //                     };
  //                   })
  //                 : null,
  //           };
  //         }),
  //       };
  //     });

  //     setUserMenus(userMenu);
  //   }
  // }, [data]);

  const sidebarMenus = useMemo(() => {
    if (!data) return [];

    const dashboardMenu = {
      "_id": 1,
      "id": 1,
      "name": "Analytics",
      "label": "Analytics",
      "urlkey": "",
      "icon": "fa fas-analytics",
      "color": "",
      "modules": [
        {
          "_id": 1,
          "id": 1,
          "name": "Dashboard",
          "label": "Dashboard",
          "urlkey": "dashboard",
          "icon": "icon icon-dashboard",
          "submodule": []
        }
      ]
    }

    const newData = [dashboardMenu, ...data];

    const userMenu = newData.map((item) => {
      return {
        label: item.name,
        key: item.id + item.urlkey,
        icon: <i className={item.icon} />,
        type: "group",

        children: item?.modules?.map((child) => {
          return {
            label:
              child?.submodule?.length === 0 ? (
                <Link to={`/${child.urlkey}`}>{child.label}</Link>
              ) : (
                child.label
              ),
            key: child.id + child.urlkey,
            icon: <i className={child.icon} />,
            popupClassName: getNavStyleSubMenuClass,
            children:
              child?.submodule?.length !== 0
                ? child?.submodule?.map((child2) => {
                  return {
                    label: child2.menu?.length === 0 ? (
                      <Link to={`/${child.urlkey}/${child2.urlkey}`}>{child2.label}</Link>
                    ) : child2.label,
                    key: child2.id + child2.urlkey,
                    children:
                      child2?.menu?.length !== 0
                        ? child2?.menu?.map((child3) => {
                          if (
                            `/${child.urlkey}/${child3.urlkey}` ===
                            location.pathname
                          ) {
                            setSelectedKeys([
                              `/${child.urlkey}/${child3.urlkey}`,
                            ]);
                          }
                          return {
                            label: (
                              <Link
                                to={`/${child.urlkey}/${child3.urlkey}`}
                              >
                                {child3.label}
                              </Link>
                            ),
                            key: child3.id + child3.urlkey,
                          };
                        })
                        : null,
                  };
                })
                : null,
          };
        }),
      };
    });

    return userMenu;
  }, [data]);

  useEffect(() => {
    setUserMenus(sidebarMenus);
  }, [data]);


  const userMenuOptions = (
    <ul className="gx-user-popover">
      {/* <li>My Account</li> */}
      <li>
        <Link style={{ color: "#545454" }} to="/user/userdetails">
          My Account
        </Link>
      </li>
      <li>
        <Link style={{ color: "#545454" }} to="/user/userdetails?from=pass">
          Change Password
        </Link>
      </li>
      <li onClick={logouthandler}>Logout</li>
    </ul>
  );

  if (isLoading) {
    return <SidebarLoading isLoading={isLoading} />;
  }
  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div
        className="gx-sidebar-content sidebar__container">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        >
          <Popover
            placement="bottomRight"
            content={userMenuOptions}
            trigger="click"
          >
            <div className="sidebar__user">
              <div className="user__info">
                <p className="gx-avatar-name name">{user?.name}</p>
                <p className="gx-avatar-name company">{user?.email}</p>
              </div>
              <div className="user__avatar">
                <Avatar src={GetUserImage()} alt={GetUserInfo("name")} />
              </div>
            </div>
          </Popover>
        </div>
        <CustomScrollbars
          className={"gx-layout-sider-scrollbar " + css`
          background: #fff;
       
          > div:first-child{
            background-color: #fff;
            margin-bottom: 105px!important;
          }
         `}
        >
          <Menu
            // defaultOpenKeys={defaultOpenKeys}
            // selectedKeys={selectedKeys}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
            className="chms__sidebar"
            style={{ background: sideBarColor }}
            items={userMenus}
          />
        </CustomScrollbars>
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
